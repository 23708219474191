import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Router } from '../routes.mjs';

export const getRedirectLocation = async (path: string) => {
  const { redirected, url } = await fetch(
    path, {
      method: 'HEAD',
      redirect: 'follow',
      headers: typeof window === 'undefined' ? {
        'x-redirect-test': 'true',
      } : {},
    },
  );

  if (redirected) {
    return url;
  }

  return null;
};

const Redirect = () => {
  const { asPath } = useRouter();
  const path = asPath.split('?')[0];

  useEffect(() => {
    getRedirectLocation(path).then((location) => {
      if (location) {
        Router.replaceRoute(location);
      }
    });
  }, [path]);

  return null;
};

export default Redirect;
