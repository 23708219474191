import React from 'react';
import Error from 'next/error';
import Redirect from '../components/Redirect';
import ClearSiteDataButton from '../ClearSiteDataButton';

const NotFound = () => (
  <>
    <ClearSiteDataButton positionAbsolute />
    <Error statusCode={404} />
    <Redirect />
  </>
);

export default NotFound;
