import Button from '@fuww/library/src/Button';
import { useIntl } from 'react-intl';
import React from 'react';
import messages from './lib/messages.mjs';
import clearSiteData from './lib/clearSiteData';

interface ClearSiteDataButtonProperties {
  positionAbsolute?: boolean;
  buttonLabel?: string;
}

const ClearSiteDataButton = ({
  positionAbsolute,
  buttonLabel,
}: ClearSiteDataButtonProperties) => {
  const intl = useIntl();

  return (
    <Button
      variant="contained"
      onClick={clearSiteData}
      sx={positionAbsolute ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      } : {
        display: 'block',
        margin: '0 0 0 0.5rem',
        background: '#d32f2f',
      }}
    >
      {buttonLabel ?? intl.formatMessage(messages.resetAndReload)}
    </Button>
  );
};

export default ClearSiteDataButton;
